import { lazy } from 'react';
import { RouteConfig } from 'react-router-config';

const adminDashboard = lazy(() => import('./LeagueAdminDashboard'));
const costCalculator = lazy(() => import('./tools/cost-calculator/CostCalculator'));
const divisions = lazy(() => import('./divisions/Divisions'));
const schedule = lazy(() => import('./scheduling/Schedule'));
const tournaments = lazy(() => import('./tournaments/Tournaments'));

export const leagueAdminRoutes: RouteConfig[] = [
  {
    path: '/admin/league/:leagueName/',
    component: adminDashboard,
    settings: {
      logo: '/assets/images/logos/logo_pandamonium.png',
    }, //TODO this needs to be dynamic
    exact: true,
  },
  {
    path: '/admin/league/:leagueName/divisions',
    component: divisions,
    exact: true,
  },
  {
    path: '/admin/league/:leagueName/divisions/tournaments/:tournamentId',
    component: tournaments,
    exact: true,
  },
  {
    path: '/admin/league/:leagueName/schedule',
    component: schedule,
    exact: true,
  },
  {
    path: '/admin/league/:leagueName/tools/cost-calculator',
    component: costCalculator,
    exact: true,
  },
];
