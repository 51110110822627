import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/redux/Store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig } from 'axios';
import { IDivision } from '@dex/models/division.model';
import { ISeason } from '@dex/models/season.model';

const baseUrl = process.env.REACT_APP_BFF_BASE_URL || 'https://bff.dexesports.gg';

export const fetchDivisions = createAsyncThunk(
  'division/fetchDivisions',
  async (leagueId: string) => {
    const url = `${baseUrl}/division/list/${leagueId}`;
    const response = await axios.get(url);

    if (response.status !== 200) {
      throw new Error('Error fetching divisions');
    }

    return response.data as IDivision[];
  }
);

export const fetchSubDivisions = createAsyncThunk(
  'division/fetchDivisions',
  async (leagueId: string, divisionId) => {
    const config: AxiosRequestConfig = {
      params: {
        organizationId: leagueId,
        divisionId,
      },
    };
    const url = `${baseUrl}/division/subdivsion/list/`;
    const response = await axios.get(url, config);

    if (response.status !== 200) {
      throw new Error('Error fetching divisions');
    }

    return response.data as IDivision[];
  }
);

export const fetchSeason = createAsyncThunk(
  'division/fetchSeason',
  async (seasonId: string) => {
    const url = `${baseUrl}/season/${seasonId}`;
    const response = await axios.get(url);

    if (response.status !== 200) {
      throw new Error('Error fetching season');
    }

    return response.data as ISeason;
  }
);

const useFetchDivisions = (leagueName: string) => {
  const dispatch = useDispatch<AppDispatch>();
  const divisions = useSelector((state: RootState) => state.division.divisions);
  const loading = useSelector((state: RootState) => state.division.loading);
  const error = useSelector((state: RootState) => state.division.error);

  useEffect(() => {
    if (leagueName) dispatch(fetchDivisions(leagueName));
  }, [dispatch, leagueName]);

  return { divisions, loading, error };
};

const useFetchSeason = (seasonId: string) => {
  const dispatch = useDispatch<AppDispatch>();
  const season = useSelector(
    (state: RootState) => state.division.selectedSubDivision.selectedSeason.season
  );
  const loading = useSelector(
    (state: RootState) => state.division.selectedSubDivision.selectedSeason.loading
  );
  const error = useSelector(
    (state: RootState) => state.division.selectedSubDivision.selectedSeason.error
  );

  useEffect(() => {
    if (seasonId) dispatch(fetchSeason(seasonId));
  }, [dispatch, seasonId]);

  return { season, loading, error };
};

export { useFetchDivisions, useFetchSeason };
