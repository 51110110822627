import { IPlayerStats } from '@dex/models/stats/player-stats.model';

export interface IPlayerStatsState {
  items?: IPlayerStats<any>[];
  page: number;
  pageSize: number;
  totalItems: number;
  error: any;
  loading: boolean;
}

export const initialPlayerStatsState: IPlayerStatsState = {
  items: [],
  page: 0,
  pageSize: 10,
  totalItems: 10,
  error: null,
  loading: false,
};
