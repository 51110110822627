import { IDivision } from '@dex/models/division.model';
import { ISeason } from '@dex/models/season.model';

export interface IDivisionState {
  selectedDivision: any;
  selectedSubDivision?: any;
  divisions: IDivision[];
  error: any;
  loading: boolean;
}

export const initialDivisionState: IDivisionState = {
  selectedDivision: { id: '', name: 'All Games' },
  selectedSubDivision: { id: '1', name: 'All Subdivisions' },
  divisions: [],
  error: null,
  loading: false,
};

export interface ISeasonState {
  season: ISeason | null;
  selectedSeason: { season: null; loading: false; error: null };
  loading: boolean;
  error: any;
}
