/* libraries */
import '../fake-db';
import React, { Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import history from '@history';
import { PersistGate } from 'redux-persist/integration/react';
import { renderRoutes } from 'react-router-config';

/* styles */
import '../styles/app/app.scss';

/* routes */
import routes from './root.routes';
import RootRoutes from './root.routes';

/* misc */
import AppContext from './appContext';
import { Loading } from '@dex';

/* redux */
import { persistor, store } from './redux/Store';
import { Auth0Provider } from '@auth0/auth0-react';
import { Auth } from './auth/Auth';
import { saveLastVisitedPage } from './utils/session-storage.util';

const App = () => {
  useEffect(() => {
    saveLastVisitedPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location]);

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      <AppContext.Provider value={{ routes }}>
        <Provider store={store}>
          <Auth>
            <Suspense fallback={<Loading></Loading>}>
              <PersistGate loading={<Loading></Loading>} persistor={persistor}>
                {/* @ts-ignore */}
                <Router history={history}>{renderRoutes(RootRoutes)}</Router>
              </PersistGate>
            </Suspense>
          </Auth>
        </Provider>
      </AppContext.Provider>
    </Auth0Provider>
  );
};

export default App;
