import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import { RouteConfig } from 'react-router-config';

const Login = lazy(() =>
  import('../../app/auth/login.component').then((module) => ({
    default: module.LoginComponent,
  }))
);

const Home = lazy(() =>
  import('./home.component').then((module) => ({
    default: module.Home,
  }))
);

const homeRoutes: RouteConfig[] = [
  {
    path: '/home',
    component: Home,
    auth: authRoles.guest,
    exact: true,
  },
  {
    path: '/login/',
    component: Login,
  },
];

export default homeRoutes;
