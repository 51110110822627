/* libraries */
import { createSlice } from '@reduxjs/toolkit';

/* redux */
import { initialAuthState } from '../state/auth.state';

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    setAccessToken: (state, action) => {
      try {
        // Decode the JWT token
        state.accessToken = action.payload;
      } catch (error) {
        console.error('Failed to decode JWT token:', error);
      }
    },
    setIdToken: (state, action) => {
      try {
        // Decode the JWT token
        state.idToken = action.payload;
      } catch (error) {
        console.error('Failed to decode JWT token:', error);
        // Handle error appropriately, e.g., set an error state or log out the user
      }
    },
  },
});

export const { actions: authActions, reducer: authReducer } = authSlice;
export const { setAccessToken, setIdToken } = authActions;
