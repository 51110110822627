import { IUpcomingEvent } from '@dex/models/events/upcoming-event.model';

export interface IUpcomingEventsState {
  events: IUpcomingEvent<any>[];
  error: any;
  loading: boolean;
}

export const initialUpcomingEventsState: IUpcomingEventsState = {
  events: [],
  error: null,
  loading: false,
};
