/* libs */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/redux/Store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { IUpcomingEvent } from '@dex/models/events/upcoming-event.model';

const baseUrl = process.env.REACT_APP_BFF_BASE_URL || 'https://bff.dexesports.gg';

interface IFetchUpcomingEventsParams {
  organizationId: string;
  divisionId?: string;
  subDivisionId?: string;
}

export const fetchUpcomingEvents = createAsyncThunk(
  'division/fetchUpcomingEvents',
  async (params: IFetchUpcomingEventsParams) => {
    let url = `${baseUrl}/events/upcoming?organizationId=${params.organizationId}`;

    const queryParams = [];
    if (params.divisionId && params.divisionId !== '1') {
      queryParams.push(`divisionId=${params.divisionId}`);

      if (params.subDivisionId && params.subDivisionId !== '1') {
        queryParams.push(`subDivisionId=${params.subDivisionId}`);
      }

      if (queryParams.length > 0) {
        url += `&${queryParams.join('&')}`;
      }
    }

    const response = await axios.get(url);

    if (response.status !== 200) {
      throw new Error('Error fetching events');
    }

    return response.data as IUpcomingEvent<any>[];
  }
);

const useFetchUpcomingEvents = (
  organizationId: string,
  divisionId?: string,
  subDivisionId?: string
) => {
  const dispatch = useDispatch<AppDispatch>();
  const upcomingEvents = useSelector((state: RootState) => state.upcomingEvents.events);
  const loading = useSelector((state: RootState) => state.upcomingEvents.loading);
  const error = useSelector((state: RootState) => state.upcomingEvents.error);

  useEffect(() => {
    dispatch(fetchUpcomingEvents({ organizationId, divisionId, subDivisionId }));
  }, [dispatch, organizationId, divisionId, subDivisionId]);

  return { upcomingEvents: upcomingEvents, loading, error };
};

export default useFetchUpcomingEvents;
