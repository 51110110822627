/* libraries */
import { createSlice } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';

/* redux */
import { initialUserState } from '../state/user.state';
import { IdToken, User } from '@auth0/auth0-react';

export const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    setUser: (state, action) => {
      try {
        // Decode the JWT token
        const decodedToken: User = jwtDecode<IdToken>(action.payload);

        // Set the decoded token as the current user
        state.currentUser = { ...decodedToken };
      } catch (error) {
        console.error('Failed to decode JWT token:', error);
        // Handle error appropriately, e.g., set an error state or log out the user
      }
    },
  },
});

export const { actions: userActions, reducer: userReducer } = userSlice;
export const { setUser } = userActions;
