import { IdToken } from '@auth0/auth0-react';
export interface IUserState {
  idToken?: IdToken | null;
  accessToken?: IdToken | null;
  error: any;
  loading: boolean;
}

export const initialAuthState: IUserState = {
  idToken: null,
  accessToken: null,
  error: null,
  loading: false,
};
