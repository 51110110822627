import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/redux/Store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig } from 'axios';
import { ICarouselImage } from '@dex/models/carousel-image.model';
import { IBlog } from '@dex/models/blog.model';

const baseUrl = process.env.REACT_APP_BFF_BASE_URL || 'https://bff.dexesports.gg';

export const fetchBlogs = createAsyncThunk(
  'news/fetchBlogs',
  async ({
    organizationId,
    divisionId,
    subDivisionId,
    pageNumber,
    pageSize,
  }: {
    organizationId?: string;
    divisionId?: string;
    subDivisionId?: string;
    pageNumber?: number;
    pageSize?: number;
  }) => {
    const url = `${baseUrl}/news/blogs`;
    const config: AxiosRequestConfig = {
      params: {
        ...(organizationId && { organizationId }),
        ...(divisionId && { divisionId }),
        ...(subDivisionId && subDivisionId !== '1' && { subDivisionId }),
        ...(pageNumber && { pageNumber }),
        ...(pageSize && { pageSize }),
      },
    };

    const response = await axios.get(url, config);

    if (response.status !== 200) {
      throw new Error('Error fetching blogs');
    }
    return response.data as IBlog[];
  }
);

export const fetchImages = createAsyncThunk(
  'news/fetchImages',
  async ({
    organizationId,
    divisionId,
    subDivisionId,
  }: {
    organizationId: string;
    divisionId: string;
    subDivisionId: string;
  }) => {
    const url = `${baseUrl}/news/banners`;

    const response = await axios.get(url, {
      params: {
        organizationId,
        divisionId: divisionId === '' || divisionId === '1' ? null : divisionId,
        subDivisionId:
          subDivisionId === '1' || subDivisionId === null ? null : subDivisionId,
      },
    });
    if (response.status !== 200) {
      throw new Error('Error fetching images');
    }

    return response.data as ICarouselImage[];
  }
);

const useFetchBlogs = (
  organizationId?: string,
  divisionId?: string,
  subDivisionId?: string,
  pageNumber?: number,
  pageSize?: number
) => {
  const dispatch = useDispatch<AppDispatch>();
  const blogs = useSelector((state: RootState) => state.news.blogs) || [];
  const loading = useSelector((state: RootState) => state.news.loading) || false;
  const error = useSelector((state: RootState) => state.news.error) || null;

  useEffect(() => {
    if (subDivisionId === '1' || subDivisionId === null) {
    }

    dispatch(
      fetchBlogs({ organizationId, divisionId, subDivisionId, pageNumber, pageSize })
    );
  }, [dispatch, organizationId, divisionId, subDivisionId, pageNumber, pageSize]);

  return { blogs, loading, error };
};

const useFetchImages = (
  organizationId: string,
  divisionId: string,
  subDivisionId: string
) => {
  const dispatch: any = useDispatch<AppDispatch>();
  const images = useSelector((state: RootState) => state.news.images.list) || [];
  const loading = useSelector((state: RootState) => state.news.images.loading) || false;
  const error = useSelector((state: RootState) => state.news.images.error) || null;

  useEffect(() => {
    dispatch(fetchImages({ organizationId, divisionId, subDivisionId }));
  }, [dispatch, organizationId, divisionId, subDivisionId]);

  return { images, loading, error };
};

export { useFetchBlogs, useFetchImages };
