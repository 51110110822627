/* services */
import { GUID } from '@dex';
import Mock from '../mock';

/* models */
import { IBlog } from '@dex/models/blog.model';

const BlogsDB: { list: IBlog[] } = {
  list: [
    {
      id: '1',
      organizationId: '1c808c48-38b4-412b-a6bc-8d679b51c438',
      divisionId: '6b197661-cc9f-41c0-9403-30e75d327d43',
      subDivisionId: '179d25a5-15e9-4846-9a5a-955a01186687',
      createdOn: new Date(new Date().setHours(-5)),
      updatedOn: new Date(new Date().setHours(-5)),
      imageUrl: '/assets/images/blog/league-of-legends-team.png',
      title: 'League of Legends Spring Split to Start April 2nd!',
      alt: 'New season split for League of Legends!',
      author: {
        id: new GUID().toString(),
        name: 'PandaBear',
        email: 'pandabear@dexesports.gg',
      },
      content:
        'Lorem ipsum dolor sit amet, vix eu justo ullum etiam, quem dolorum detraxit cu has. An ferri probatus petentium nec, eos cu etiam efficiantur. Cu duo oportere constituto sententiae, sale appellantur mei ne. Hinc viris pericula te ius, id mei iudicabit concludaturque. Usu elit elitr patrioque at, odio delicata quo ei. Cum ex quas possit rationibus, iriure partiendo in pri. Ea qui harum aliquid instructior, ei inimicus repudiare suscipiantur sed, pro id liber tractatos.',
      comments: [
        {
          id: new GUID().toString(),
          author: {
            id: new GUID().toString(),
            name: 'Panda Bear',
            email: 'pandabear@dexesports.gg',
            avatarUrl: '/assets/images/players/nawbrah.jpg',
          },
          timeStamp: new Date(new Date().setHours(-4)),
          message: 'Looks good! Super exciting!',
          likes: 8,
        },
      ],
      likes: 237,
    },
    {
      id: '2',
      organizationId: '1c808c48-38b4-412b-a6bc-8d679b51c438',
      divisionId: '5d3ede65-4392-1234-b82b-e1fc0776ba4f',
      subDivisionId: '179d25a5-15e9-4846-9a5a-955a01186687',
      createdOn: new Date(new Date().setHours(-3)),
      updatedOn: new Date(new Date().setHours(-3)),
      imageUrl: '/assets/images/blog/valorant-team.png',
      title: 'Valorant spring split to start April 2nd!',
      alt: 'New Season Split for Valorant!',
      author: {
        id: new GUID().toString(),
        name: 'PandaBear',
        email: 'pandabear@dexesports.gg',
      },
      content:
        'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?',
      comments: [
        {
          id: new GUID().toString(),
          author: {
            id: new GUID().toString(),
            name: 'Polar Bear',
            email: 'polarbear@dexesports.gg',
          },
          timeStamp: new Date(new Date().setHours(-2)),
          message: 'Looks good! Super exciting',
          likes: 3,
        },
        {
          id: new GUID().toString(),
          author: {
            id: new GUID().toString(),
            name: 'Panda Bear',
            email: 'pandabear@dexesports.gg',
            avatarUrl: '/assets/images/players/nawbrah.jpg',
          },
          timeStamp: new Date(new Date().setHours(-4)),
          message: `I can't wait to compete!`,
          likes: 1,
        },
        {
          id: new GUID().toString(),
          author: {
            id: new GUID().toString(),
            name: 'Grizzly Bear',
            email: 'grizzlybear@dexesports.gg',
          },
          timeStamp: new Date(new Date().setHours(-4)),
          message: 'Rawr!',
        },
      ],
      likes: 129,
    },
  ],
};

Mock.onGet('/api/blogs').reply(async (config: any) => {
  // Extract query string parameters
  const organizationId: string | null = config.params?.organizationId ?? null;
  const divisionId: string | null = config.params?.divisionId ?? null;
  const subDivisionId: string | null = config.params?.subDivisionId ?? null;

  let filteredBlogs: IBlog[];
  // Handle the different conditions based on the provided parameters
  if (organizationId === null) {
    filteredBlogs = BlogsDB.list; // Return the entire list if all are null
  } else if (divisionId === null) {
    filteredBlogs = BlogsDB.list.filter((blog) => blog.organizationId === organizationId);
  } else if (subDivisionId === null) {
    filteredBlogs = BlogsDB.list.filter(
      (blog) => blog.organizationId === organizationId && blog.divisionId === divisionId
    );
  } else {
    filteredBlogs = BlogsDB.list.filter(
      (blog) =>
        blog.organizationId === organizationId &&
        blog.divisionId === divisionId &&
        blog.subDivisionId === subDivisionId
    );
  }

  return [200, filteredBlogs];
});
