import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/redux/Store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { IPlayerStats } from '@dex/models/stats/player-stats.model';

const baseUrl = process.env.REACT_APP_BFF_BASE_URL || 'https://bff.dexesports.gg';

export const fetchAllPlayerStatsForSeason = createAsyncThunk(
  'playerStats/fetchAllPlayerStatsForSeason',
  async ({
    seasonId,
    page,
    pageSize,
  }: {
    seasonId: string;
    page?: number;
    pageSize?: number;
  }) => {
    const url = `${baseUrl}/stats/players/${seasonId}`;
    const response = await axios.get(url, {
      params: {
        page,
        pageSize,
      },
    });

    if (response.status !== 200) {
      throw new Error('Error fetching all player stats for season.');
    }
    return response.data as IFetchAllPlayerStatsResponse;
  }
);

const useFetchAllPlayerStatsForSeason = (seasonId: string, page = 0, pageSize = 10) => {
  const dispatch = useDispatch<AppDispatch>();
  const items = useSelector((state: RootState) => state.playerStats.items) || [];
  const loading = useSelector((state: RootState) => state.playerStats.loading) || false;
  const error = useSelector((state: RootState) => state.playerStats.error) || null;
  const totalItems = useSelector((state: RootState) => state.playerStats.totalItems) || 0;

  useEffect(() => {
    dispatch(
      fetchAllPlayerStatsForSeason({
        seasonId,
        page,
        pageSize,
      })
    );
  }, [dispatch, seasonId, page, pageSize]);

  return { items, pageSize, page, loading, error, totalItems };
};

interface IFetchAllPlayerStatsResponse {
  page: number;
  pageSize: number;
  items: IPlayerStats<any>[];
  totalItems: number;
}

export { useFetchAllPlayerStatsForSeason };
