/* libraries */
import { createSlice } from '@reduxjs/toolkit';

/* redux */
import { initialPlayerStatsState } from '../state/player-stats.state';
import { fetchAllPlayerStatsForSeason } from 'app/views/app/league/stats/player-stats.service';

export const playerStatsSlice = createSlice({
  name: 'playerStats',
  initialState: initialPlayerStatsState,
  reducers: {},
  extraReducers: (builder) => {
    /* Fetch All Blogs */
    builder.addCase(fetchAllPlayerStatsForSeason.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllPlayerStatsForSeason.fulfilled, (state, action) => {
      state.loading = false;
      state.items = action.payload.items;
      state.page = action.payload.page;
      state.pageSize = action.payload.pageSize;
      state.totalItems = action.payload.totalItems;
      state.error = null;
    });
    builder.addCase(fetchAllPlayerStatsForSeason.rejected, (state, action) => {
      state.loading = false;
      state.items = [];
      state.page = 0;
      state.pageSize = 10;
      state.totalItems = 0;
      state.error = action.error.message;
    });
  },
});

export const { actions: playerStatsActions, reducer: playerStatsReducer } =
  playerStatsSlice;
