const saveLastVisitedPage = () => {
  const currentUrl = window.location.pathname + window.location.search;
  sessionStorage.setItem('lastVisitedPage', currentUrl);
};

const getLastVisitedPage = () => {
  return sessionStorage.getItem('lastVisitedPage') || '/home';
};

export { saveLastVisitedPage, getLastVisitedPage };
