/* libraries */
import { createSlice } from '@reduxjs/toolkit';

/* redux */
import { initialDivisionState } from '../state/division.state';
import {
  fetchDivisions,
  fetchSeason,
} from 'app/views/app/league/divisions/divisions.service';

export const divisionSlice = createSlice({
  name: 'division',
  initialState: initialDivisionState,
  reducers: {
    setSelectedDivision: (state, action) => {
      state.selectedDivision = action.payload;
      state.selectedSubDivision = undefined;
    },
    setSelectedSubDivision: (state, action) => {
      state.selectedSubDivision = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDivisions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchDivisions.fulfilled, (state, action) => {
      state.loading = false;
      state.divisions = action.payload;
      state.error = null;
    });
    builder.addCase(fetchDivisions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(fetchSeason.pending, (state) => {
      state.selectedSubDivision.selectedSeason.loading = true;
    });
    builder.addCase(fetchSeason.fulfilled, (state, action) => {
      state.selectedSubDivision.selectedSeason = {
        loading: false,
        season: action.payload,
        error: null,
      };
    });
    builder.addCase(fetchSeason.rejected, (state, action) => {
      state.selectedSubDivision.selectedSeason = {
        loading: false,
        season: null,
        error: action.error.message,
      };
    });
  },
});

export const { actions: divisionActions, reducer: divisionReducer } = divisionSlice;
export const { setSelectedDivision, setSelectedSubDivision } = divisionActions;
