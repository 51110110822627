/* libs */
import axios from 'axios';

/* services */
import Mock from '../mock';

/* models */
import { IRules } from '@dex/models/rules.model';

/* files */
import rules1 from './files/rules/sample1.rules.md';
import rules2 from './files/rules/sample2.rules.md';

const RulesDB: { list: IRules[] } = {
  list: [
    {
      id: 'a4d8f1c6-9c2d-4b8e-9e6e-3c1d6d5a2b5d',
      leagueName: '12345678',
      divisionId: '6b197661-cc9f-41c0-9403-30e75d327d43',
      subDivisionId: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
      rules: rules1,
    },
    {
      id: 'a4d8f1c6-9c2d-4b8e-9e6e-3c1d6d5a2b5d',
      leagueName: '12345678',
      divisionId: '6b197661-cc9f-41c0-9403-30e75d327d43',
      subDivisionId: '179d25a5-15e9-4846-9a5a-955a01186687',
      rules: rules2,
    },
  ],
};

Mock.onGet('/api/rules').reply(async (config: any) => {
  const response = await axios.get(RulesDB.list[0].rules);
  return [200, response.data];
});
